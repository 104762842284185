import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@sweb-front/store';
import { RefusedIconPage } from '@vat/components';
import {
  ContentWrapper,
  SectionWrapper,
  SVGIconWrapper,
  TitleCmp,
  ContentParagraph,
  BoldParagraph,
} from './styles';
import useRefusedPayment from './useRefusedPayment';
import { WAY_TYPE_CB } from 'src/libs/services/constants';

const RefusedPayment = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const parameters = useAppSelector((state) => state.parameters.state);
  const { wayType } = parameters;
  useRefusedPayment(opportunity, parameters);

  const { monextResponse } = useAppSelector((state) => state.cardPayment.state);

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <RefusedIconPage />
      </SVGIconWrapper>
      <ContentWrapper>
        <TitleCmp>{t('responses.refused.title')}</TitleCmp>
        <ContentParagraph>{t('responses.refused.gratitude')}</ContentParagraph>
        <ContentParagraph>
          {t('responses.refused.insufficientRessources')}
        </ContentParagraph>
        <ContentParagraph>
          {t('responses.refused.informationWay')}
        </ContentParagraph>
        {wayType === WAY_TYPE_CB &&
          (monextResponse?.cardId || monextResponse?.cardRef) && (
            <BoldParagraph>
              {t('responses.refused.securedBankInformations')}
            </BoldParagraph>
          )}
      </ContentWrapper>
    </SectionWrapper>
  );
};

export default RefusedPayment;
