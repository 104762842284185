import { Loader } from '@sweb-front/components';
import { useCreditParameters } from '@sweb-front/hooks';
import { axiosInstance } from '@sweb-front/services';
import {
  IAppStep,
  INavigationState,
  IStep,
  clearNavigationStore,
  clearStore,
  fetchOpportunity,
  fetchWay,
  getActiveStep,
  getStepByExternalAppName,
  setParams,
  setRightToEcard,
  setRoutingContexts,
  setSteps,
  setVatType,
  updateOpportunityStatus,
  updateSteps,
  useAppDispatch,
  isOpenBankingResultOk,
  updateParcoursNavigation,
  setEndParcours,
  setMonexResponse,
  setOnboardingResponse,
} from '@sweb-front/store';
import { LoadingWrapper } from '@sweb-front/styles';
import {
  CHANELTYPE,
  IParametersArgs,
  IRecap,
  IOnBoResponse,
  Monext3DsResponse,
  MonextResponse,
} from '@sweb-front/types';
import {
  ascSort,
  isEmptySearchParams,
  isSearchParamsHasKey,
  isStringEmpty,
  isUrlContainsRedirectionParams,
  trackCustomAction,
} from '@sweb-front/utils';
import {
  APPPARAMS,
  COOKIES,
  ERRORKEY,
  ERRORPAGE,
  ERRORPAGEVATCB,
  ERRORPAGEVATRIB,
  EXPIREDSESSIONVATCB,
  EXPIREDSESSIONVATRIB,
  IDENTIFICATION_CETELEM,
  INFORMATIONBANCAIRES,
  LIVENESS,
  MENTIONSLEGALES,
  MENTIONSLEGALES100,
  MENTIONSLEGALES140,
  OBCONNEXIONERROR,
  OPENBANKING,
  OPENBANKINGCHOICE,
  ORDER_SUMMARY,
  PIECESJUSTIFICATIVES,
  PLANBKEY,
  REFUSEDREPONSE,
  SCORE,
  SMSMENTIONLEGALE,
  SOLVARIB,
  STOREDASSUKEY,
  STOREDPRFKEY,
  STOREDSOLVAKEY,
  SUCCESSREPONSE,
  THEME,
  WAITINGRESPONSE,
  getMatchingComponents,
  THREEDS,
  REDIRECTION,
  OPPORTUNITYSTATUS,
  ERRORBUSINESSCODE,
} from '@vat/configuration';
import { formatToBnpString, postData } from '@vat/containers';
import { checkAvailability } from '@vat/services';
import {
  AppOptions,
  updateRoutingContexts,
  useCloseOpportunity,
  useManagingExternalApp,
} from '@vat/utils';

import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { regexp } from './constants';
import { WAY_TYPE_CB } from 'src/libs/services/constants';

export interface IAppConfig {
  msBffBaseURL: string;
  timeout: number;
  env: string;
  apiKeyForTokenAPI: string;
}

export type TToken = JwtPayload & {
  rightToEcard: string;
  requestId: string;
};

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
}

export const getStickyMessage = (url: string) => {
  let title = 'errorPage.sticky.title';
  let message = 'errorPage.sticky.message';
  if ([SUCCESSREPONSE].includes(url)) {
    title = 'responses.accepted.sticky.title';
    message = 'responses.accepted.sticky.message';
  } else if (REFUSEDREPONSE.includes(url)) {
    title = 'responses.refused.sticky.title';
    message = 'responses.refused.sticky.message';
  } else if (WAITINGRESPONSE.includes(url)) {
    title = 'responses.maybe.sticky.title';
    message = 'responses.maybe.sticky.message';
  }

  return {
    title,
    message,
  };
};

/**
 * Compute the step to display on the stepline with its new step number
 * @param {IStep[]} steps The initial steps
 * @return {IStep[]} Return the steps to display
 */
export const computeSteps = (steps: IStep[]): IStep[] => {
  return (steps ?? [])
    .filter((s) => !s.isSubStep)
    .filter((s) => s && !s.isHeaderIgnored)
    .map((s, index: number) => ({
      ...s,
      step: index + 1,
    }));
};

export const buildRouteFromSteps = (stepList?: IStep[]) => {
  return (stepList ?? []).map(({ components, paths }) =>
    paths?.map((path) => (
      <Route
        key={path}
        path={path}
        element={getMatchingComponents(components) as any}
      />
    ))
  );
};

export const clearLocalStorageData = () => {
  trackCustomAction(
    `Clear local storage-token: "${localStorage.getItem('token')}"`,
    false
  );
  localStorage.removeItem('token');
  localStorage.removeItem(STOREDSOLVAKEY);
  localStorage.removeItem(STOREDASSUKEY);
  localStorage.removeItem(STOREDPRFKEY);
  localStorage.removeItem(PLANBKEY);
  localStorage.removeItem(SOLVARIB);
  localStorage.removeItem(THEME);
  localStorage.removeItem(SCORE);
  localStorage.removeItem(ERRORBUSINESSCODE);
};

export const fetchOpportunityFromBase = async (
  token: string,
  callbackSuccess: (result) => void,
  callbackError: (e) => void
) => {
  axios.defaults.headers.common.token = token;
  try {
    const response = await axiosInstance().get(
      `vendors/opportunities/v1/opportunity`
    );
    callbackSuccess(response);
  } catch (e) {
    callbackError(e);
  }
};

export const bagHasTradeIn = (recap: Partial<IRecap>): boolean => {
  return recap.products.some(
    (product) =>
      product.tradeIn &&
      Object.keys(product).length !== 0 &&
      product.constructor === Object
  );
};

export const bagHasSummaryCashFund = (recap: Partial<IRecap>): boolean => {
  return recap.summaryCashFund?.cashDetails?.length > 0;
};

const useApp = ({
  searchParams,
  steps,
  appNavigation,
  storedToken,
}: {
  searchParams: URLSearchParams;
  steps: IStep[];
  appNavigation: INavigationState;
  storedToken: string | undefined;
}) => {
  const location = useLocation();
  const isRibRegExp = new RegExp('vatr', 'i');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const navSteps = appNavigation?.steps;
  const [isWaitingPage, setWaitingPage] = useState<boolean>(false);
  const [waitingStep, setWaitingStep] = useState<string>('');
  const [numVendor, setNumVendor] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(1);
  const [currentStepWithSubStep, setCurrentStepWithSubStep] = useState(1);
  const [isPageWithNoHeader, setIsPageWithNoHeader] = useState<boolean>();
  const [isPageWithNoFooter, setIsPageWithNoFooter] = useState<boolean>(true);
  const lastPage = location.pathname;
  const [internalPageLoading, setInternalPageLoading] = useState(true);
  const activeStep = getActiveStep(navSteps);

  const [containedError, setContainedError] = useState(false);
  const { initMonext, init3DS, initOnBo, goToExternalAppByForm } =
    useManagingExternalApp();
  const [isAppLoading, setIsAppLoading] = useState(true);
  const { recalculateCreditParameters } = useCreditParameters();
  const [isOrderSummaryPage, setIsOrderSummaryPage] = useState(false);
  const { updateAbortErrorMessage } = useCloseOpportunity();

  const updatePageIsLoading = (state: boolean) => {
    setInternalPageLoading(state);
  };

  const FREEPAGES = [
    MENTIONSLEGALES,
    MENTIONSLEGALES100,
    MENTIONSLEGALES140,
    COOKIES,
    SMSMENTIONLEGALE,
  ];

  const [appOptions, setAppOptions] = useState<AppOptions>({
    isMounted: false,
  });
  // CHECK IF LOCAL STORAGE IS UP
  const isLocalStorageUp = () => {
    let isUp = false;
    try {
      if (window.localStorage) {
        localStorage.setItem('test', '1');
        isUp =
          localStorage.getItem('test') !== null &&
          localStorage.getItem('test') !== undefined;
      }
    } catch {
      isUp = false;
    }
    if (isUp) {
      trackCustomAction(
        `Local Storage is UP :"${localStorage.getItem('test')}"`
      );
      console.log('LOCASTORAGE UP', localStorage.getItem('test'));
    } else {
      trackCustomAction(`Local Storage is DOWN`);
      console.log('LOCASTORAGE DOWN', localStorage.getItem('test'));
    }
  };

  useEffect(() => {
    if (
      location &&
      ![
        '/informations',
        '/informations-bancaires',
        '/erreur',
        '/revenus-charges',
        '/options',
        '/situation-familiale',
        '/',
      ].includes(location.pathname)
    ) {
      setAppOptions((state) => ({ ...state, isMounted: true }));
    }
    return () => {
      setAppOptions((state) => ({ ...state, isMounted: false }));
    };
  }, []);

  const updateError = useCallback(() => {
    setContainedError(true);
  }, []);

  useEffect(() => {
    // Check IF LOCAL STORAGE IS UP
    isLocalStorageUp();
    setWaitingPage(regexp.test(lastPage));
    setContainedError(false);

    // Clear the storage first
    localStorage.removeItem(ERRORKEY);
  }, []);

  // Hide the stepline (ariane) when the these pages are requested
  useEffect(() => {
    const currStep = steps?.find((s) => s.step === currentStep);
    if (currStep && currStep.paths.includes(lastPage)) {
      setIsPageWithNoHeader(currStep.isHeaderIgnored);
    } else {
      const withNoHeader = [
        SUCCESSREPONSE,
        REFUSEDREPONSE,
        REDIRECTION,
        ...FREEPAGES,
        ERRORPAGE,
        ERRORPAGEVATCB,
        ERRORPAGEVATRIB,
        EXPIREDSESSIONVATCB,
        EXPIREDSESSIONVATRIB,
        INFORMATIONBANCAIRES,
        PIECESJUSTIFICATIVES,
        WAITINGRESPONSE,
        ORDER_SUMMARY,
        IDENTIFICATION_CETELEM,
        OPENBANKINGCHOICE,
        LIVENESS,
        OPENBANKING,
        OBCONNEXIONERROR,
      ].includes(lastPage);
      setIsPageWithNoHeader(withNoHeader);
    }

    const withNoFooter =
      [
        MENTIONSLEGALES,
        SMSMENTIONLEGALE,
        COOKIES,
        ERRORPAGE,
        ERRORPAGEVATCB,
        ERRORPAGEVATRIB,
        EXPIREDSESSIONVATCB,
        EXPIREDSESSIONVATRIB,
        INFORMATIONBANCAIRES,
        PIECESJUSTIFICATIVES,
        OPENBANKINGCHOICE,
        OPENBANKING,
        OBCONNEXIONERROR,
        REDIRECTION,
      ].includes(lastPage) || regexp.test(lastPage);
    setIsPageWithNoFooter(withNoFooter);

    setIsOrderSummaryPage(
      location.pathname === '/' || location.pathname === 'order-summary'
    );
  }, [location]);

  const readParameters = useCallback(
    async (params: IParametersArgs, planB?: boolean, isNewToken?: boolean) => {
      if (!params.distributorNb || !params.overdraft || !params.productType) {
        updateAbortErrorMessage(
          `useApp::readParameters problème au niveau des données du paramétrages: "${params}"`
        );
        setContainedError(true);
        return;
      }
      try {
        const { result, appSteps } = await recalculateCreditParameters(params);
        const wayType = result?.wayType;
        dispatch(fetchWay(result));
        dispatch(setVatType(wayType.toLowerCase()));
        triggerWayTypeVATC(wayType, planB, isNewToken, params);
        if (appSteps && appSteps.length > 0) {
          dispatch(setSteps(appSteps));
        } else {
          updateAbortErrorMessage(
            'useApp::readParameters Les étapes retournées par le paramétrage sont vides'
          );
          setContainedError(true);
        }
        setIsAppLoading(false);
      } catch {
        updateAbortErrorMessage(
          "useApp::readParameters La lecture du paramétrage ne s'est pas faite correctement"
        );
        setContainedError(true);
        setIsAppLoading(false);
      }
    },
    []
  );

  const continueTreatment = (
    navigationStore,
    page: string,
    urlParams: URLSearchParams,
    lastActiveStep: IAppStep,
    applicationSteps: IAppStep[],
    containedError: boolean
  ) => {
    if (
      page === ERRORPAGE ||
      containedError ||
      FREEPAGES.includes(page) ||
      (!urlParams.has('token') && (applicationSteps ?? []).length < 1)
    ) {
      setIsAppLoading(false);
      window.history.replaceState({}, document.title, page);
      return;
    }
    /**  ----------  CAS  MONEXT  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'monext') {
      // créer une nouvelle redirection monext

      if (isStringEmpty(lastActiveStep.externalUrl)) {
        initMonext();
        return;
      }
      if (
        !isSearchParamsHasKey(urlParams, 'redirectFromMonext') ||
        (isSearchParamsHasKey(urlParams, 'redirectFromMonext') &&
          urlParams.get('returnCode') === '12')
      ) {
        // Cas où Monext est le premier débranchement, on peut le rediriger vers notre appli
        if ((applicationSteps ?? []).length === 1) {
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              isExternalUrlAlreadyVisited: false,
            })
          );
          navigate(
            lastActiveStep.isExternalUrlAlreadyVisited
              ? navigationStore.lastAppUrl
              : page
          );
          setIsAppLoading(false);
        } else {
          dispatch(
            updateSteps({
              externalAppName: 'monext',
              isExternalUrlAlreadyVisited: true,
            })
          );
          // sinon on le redirige vers monext avec les étapes déja faites
          goToExternalAppByForm(lastActiveStep);
        }
        return;
      }
    }

    /**  ----------  CAS ONBO  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'onbo') {
      // Créer une url onbo
      if (isStringEmpty(lastActiveStep.externalUrl)) {
        initOnBo();
        return;
      }

      // Cas où ONBO est le premier débranchement, on peut encore parcourir l'application si pas de SE
      if (
        (applicationSteps ?? []).length === 1 &&
        lastActiveStep.isExternalUrlAlreadyVisited
      ) {
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isExternalUrlAlreadyVisited: false,
          })
        );
        navigate(navigationStore.lastAppUrl);
        setIsAppLoading(false);
        return;
      }

      // Cas où ONBO est à une étape quelconque, on le redirige vers onbo
      if (
        !isStringEmpty(lastActiveStep.externalUrl) &&
        lastActiveStep.isExternalUrlAlreadyVisited &&
        !isSearchParamsHasKey(urlParams, 'redirectFromSignature')
      ) {
        dispatch(
          updateSteps({
            externalAppName: 'onbo',
            isExternalUrlAlreadyVisited: true,
          })
        );
        window.location.replace(lastActiveStep.externalUrl!);
        return;
      }
    }

    /**  ----------  CAS  3DS  --------- */
    /**
     * A noter ici que 3DS dépend du résultat onbo, du coup nous avons besoin des informations en réponse onbo
     * pour pouvoir initier 3DS
     *
     */

    if (lastActiveStep && lastActiveStep.externalAppName === '3ds') {
      // créer une nouvelle url 3DS
      if (isStringEmpty(lastActiveStep.externalUrl)) {
        init3DS(
          getStepByExternalAppName('onbo', applicationSteps)
            ?.redirectionParam ?? {},
          () => {
            dispatch(
              updateSteps({
                externalAppName: 'onbo',
                isSeDone: true,
                redirectionParam: 'redirectFromSignature',
              })
            );
          }
        );
        return;
      }

      if (
        !isSearchParamsHasKey(urlParams, 'redirectFrom3DS') &&
        isEmptySearchParams(urlParams, APPPARAMS.redirectFrom3DS)
      ) {
        try {
          goToExternalAppByForm(lastActiveStep);
        } catch {
          updateAbortErrorMessage(
            "3DS:: submitForm, we can't init twice the form even with the same value"
          );
          dispatch(
            updateSteps({
              externalAppName: '3ds',
              isErrorHappened: true,
            })
          );
          setContainedError(true);
        }
      }
    }

    /**  ----------  CAS  OPENBANKING  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'openBanking') {
      if (!isStringEmpty(activeStep?.externalUrl)) {
        dispatch(
          updateSteps({
            externalAppName: 'openBanking',
            isExternalUrlAlreadyVisited: true,
          })
        );
        return window.location.replace(activeStep?.externalUrl);
      }
    }

    /**  ----------  CAS  doAuth  --------- */
    if (lastActiveStep && lastActiveStep.externalAppName === 'authorization') {
      const monext3DsResponse =
        lastActiveStep?.params as unknown as Monext3DsResponse;

      if (monext3DsResponse && monext3DsResponse.threeDsRef) {
        dispatch(
          updateSteps({
            externalAppName: '3ds',
            params: monext3DsResponse,
            isErrorHappened: false,
            isSeDone: true,
          })
        );
        dispatch(
          updateParcoursNavigation({
            name: THREEDS,
            actionPageDone: true,
          })
        );

        navigate(REDIRECTION, {
          replace: true,
        });
      }
    }

    return setIsAppLoading(false);
  };

  const saveTokenInLocalStorage = (token: string) => {
    try {
      trackCustomAction(
        `readTestLocalSotrage1:"${localStorage.getItem('test')}"`
      );
      localStorage.setItem('token', token);
      const tokenSaved = localStorage.getItem('token');
      console.log(`Token saved in localStorage: "${tokenSaved}"`);
      trackCustomAction(`Token saved in localStorage: "${tokenSaved}"`);
    } catch (error) {
      const errorMsg = `Token cannot be set on LocalStorage, Retry Second Time:: "${error}"`;
      console.error(errorMsg);
      trackCustomAction(errorMsg);
      try {
        localStorage.setItem('token', token);
      } catch (exception) {
        const errorMsg = `Token cannot be set on LocalStorage for second Time: "${exception}"`;
        console.error(errorMsg);
        trackCustomAction(errorMsg);
        setContainedError(true);
      }
    }
  };

  const readOpportunities = useCallback(
    (
      nav: INavigationState,
      params: URLSearchParams,
      receivedToken?: string
    ) => {
      const hasParamsToken = params.has('token') || params.has('shortToken');
      const tokenParam = params.has('token')
        ? params.get('token')
        : receivedToken;
      const isNewToken =
        hasParamsToken && params.get('token') !== (receivedToken ?? '');

      const objLoger1 = {
        hasTo: params.has('token'),
        hasSTo: params.has('shortToken'),
        praram: receivedToken,
      };
      const objLoger2 = {
        newToken: isNewToken,
        storage: localStorage.getItem('token'),
      };
      trackCustomAction(`Eval1 URL :"${JSON.stringify(objLoger1)}"`, false);
      trackCustomAction(`Eval2 URL :"${JSON.stringify(objLoger2)}"`, true);

      let decodedToken;
      try {
        decodedToken = jwtDecode(tokenParam) as TToken;
      } catch (err) {
        trackCustomAction(
          `jwtDecode1-readOpp KO token:"${tokenParam}", error:"${err}"`
        );
        throw new Error(
          `jwtDecode1-readOpp KO token:"${tokenParam}", error:"${err}"`
        );
      }

      axios.defaults.headers.common.requestId = decodedToken.requestId;

      if (isNewToken) {
        clearStore();
        clearLocalStorageData();
        clearNavigationStore();
        saveTokenInLocalStorage(tokenParam as string);
      } else {
        trackCustomAction(`No Token has been saved : "${isNewToken}"`);
      }

      setIsAppLoading(true);

      fetchOpportunityFromBase(
        tokenParam,
        async (response) => {
          let opportunityStatus = response.data.statusCd;

          dispatch(updateOpportunityStatus(opportunityStatus));

          // Update routing context
          dispatch(
            setRoutingContexts(
              updateRoutingContexts(
                response?.data?.routingContexts ?? [],
                isNewToken ? [] : nav?.routingContexts ?? []
              )
            )
          );

          // Check if the opportunity is not cancelled (ANUL)
          if (opportunityStatus === OPPORTUNITYSTATUS.ANUL) {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            return setContainedError(true);
          } else if (opportunityStatus === OPPORTUNITYSTATUS.APPR) {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            return navigate(SUCCESSREPONSE, {
              replace: true,
            });
          } else if (opportunityStatus === OPPORTUNITYSTATUS.REFU) {
            dispatch(setEndParcours(true));
            setIsAppLoading(false);
            navigate(REFUSEDREPONSE, {
              replace: true,
            });
          }
          // Put here to avoid that user can use the same token on another browser and he can change the user
          else {
            if (isNewToken && opportunityStatus === OPPORTUNITYSTATUS.NEW) {
              axios.defaults.headers.common.token = tokenParam;
              await postData(
                'vendors/opportunities/v1/opportunity',
                {
                  statusCd: OPPORTUNITYSTATUS.ENCO,
                },
                () => {
                  let decodedToken;
                  try {
                    decodedToken = jwtDecode(tokenParam) as TToken;
                  } catch (err) {
                    trackCustomAction(
                      `jwtDecode2-readOpp KO token:"${tokenParam}", token:"${err}"`
                    );
                    throw new Error(
                      `jwtDecode2-readOpp KO token:"${tokenParam}", error:"${err}"`
                    );
                  }
                  dispatch(
                    setRightToEcard(decodedToken.rightToEcard === 'true')
                  );
                  opportunityStatus = OPPORTUNITYSTATUS.ENCO;
                  dispatch(updateOpportunityStatus(OPPORTUNITYSTATUS.ENCO));
                  window.history.replaceState({}, document.title, '/');
                },
                () => {
                  setIsAppLoading(false);
                  setContainedError(true);
                }
              );
            }

            // Displays the response page if no
            if (nav?.endParcours && !isNewToken) {
              if (opportunityStatus === OPPORTUNITYSTATUS.REFU) {
                navigate(REFUSEDREPONSE, {
                  replace: true,
                });
              } else if (opportunityStatus === OPPORTUNITYSTATUS.PAPP) {
                navigate(WAITINGRESPONSE, {
                  replace: true,
                });
              } else {
                navigate(nav?.lastAppUrl, {
                  replace: true,
                });
              }
              return setIsAppLoading(false);
              /**
               * Pour le moment on peut seulement conditionner cet état
               * car pour le statut REFU et PAPP, nous ne sommes pas sûrs que le parcours il est terminé
               * pour le cas REFU, sur orange on peut avoir un statut refus avant même de faire le final instant decision
               * et du coup on ne peut pas le conditionner ici
               */
            } else if (
              ![OPPORTUNITYSTATUS.ENCO, OPPORTUNITYSTATUS.PAPP].includes(
                opportunityStatus
              ) &&
              !isUrlContainsRedirectionParams(params)
            ) {
              setIsAppLoading(false);
              return setContainedError(true);
            } else if (isUrlContainsRedirectionParams(params)) {
              return redirectToWaitingPage(params, nav);
            }

            const persons = response.data.persons?.[0];
            const { orderChannelCd, planB, projects, bagType } = response.data;

            if (orderChannelCd !== CHANELTYPE.POS) {
              const { personalInformation } = persons;
              if (personalInformation?.birthCountryIsoCd !== 'FR') {
                persons.personalInformation.birthCityInseeCd = '';
                persons.personalInformation.birthDepartment = '';
              }
              persons.personalInformation.birthName = formatToBnpString(
                persons.personalInformation.birthName,
                30
              );
            }

            const { overdraftAmt } = response.data.offers[0].loans.find(
              (loan: { typeCd: string }) => loan.typeCd === 'PRI'
            ).financialDetails;

            const recap: IRecap = {
              ...response.data.recap,
              hasTradeIn: bagHasTradeIn(response.data.recap),
              hasSummaryCashFund: bagHasSummaryCashFund(response.data.recap),
            };

            dispatch(
              fetchOpportunity({
                distributor: response.data.distributor ?? {},
                person: {
                  ...persons,
                },
                bagType: response.data.bagType,
                distributionChannelCD: orderChannelCd,
                opportunityIdExt: response.data.opportunityIdExt ?? {},
                project: projects ?? [],
                offers: response.data.offers ?? [],
                recap: recap,
                planB: response.data.planB,
                tradeInWorkflow: response.data.tradeInWorkflow,
              })
            );

            const { distributorNb } = response.data.distributor;
            setNumVendor(distributorNb);

            const { productTypeCd } = response.data.offers[0].loans.find(
              (loan: { typeCd: string }) => loan.typeCd === 'PRI'
            ).financialProduct;

            const paramsArgs: IParametersArgs = {
              distributorNb,
              overdraft: overdraftAmt,
              productType: productTypeCd,
              bag: bagType ?? undefined, // bag
              channel: orderChannelCd ?? undefined, // channel
              knowCustomer: false, // knowCustomer
              existRib: false, // existRib
              easyPayment: false, // easyPayment
              deliveryMode: projects?.[0]?.deliveryMode ?? undefined, //
            };

            dispatch(setParams(paramsArgs));
            readParameters({ ...paramsArgs });
            if (!isNewToken) {
              continueTreatment(
                nav,
                lastPage,
                params,
                activeStep,
                navSteps,
                containedError
              );
            } else {
              navigate('/');
            }

            setIsAppLoading(false);
          }
        },
        (e) => {
          setIsAppLoading(false);
          setContainedError(true);
        }
      );
    },
    [lastPage, appNavigation]
  );

  useEffect(() => {
    let timeoutID: number;
    if (FREEPAGES.includes(lastPage)) {
      setIsAppLoading(false);
      return;
    }

    //  XTN: This information is crucial to have a fine-grained machine to machine integration with SEAP server component
    window.seapweb?.getTokenId();

    if (FREEPAGES.includes(lastPage)) {
      setIsAppLoading(false);
      return;
    }

    const hasToken =
      searchParams.has('token') || searchParams.has('shortToken');
    // Displays error page after page refresh if an error already occured in the application
    if (
      (![
        OPPORTUNITYSTATUS.ENCO,
        OPPORTUNITYSTATUS.PAPP,
        OPPORTUNITYSTATUS.APPR,
      ].includes(appNavigation?.opportunityStatus) &&
        !appNavigation?.endParcours &&
        !hasToken) ||
      (!hasToken && getActiveStep(appNavigation?.steps ?? [])?.isErrorHappened)
    ) {
      setIsAppLoading(false);
      setContainedError(true);
    }

    // Lancer seulement la lecture d'affaire sur les pages qui le requièrent
    if (searchParams.has('shortToken')) {
      const applicationId = searchParams.get('shortToken');
      axiosInstance()
        .get(`token/${applicationId}`, {
          headers: {
            apiKey: (window as unknown as WindowWithEnv)._env_
              ?.apiKeyForTokenAPI,
          },
        })
        .then((res) => {
          trackCustomAction(
            `start readOpportunities with full token "${res.data.token}"`
          );
          readOpportunities(appNavigation, searchParams, res.data.token);
        })
        .catch(() => {
          setIsAppLoading(false);
          setContainedError(true);
        });
    } else {
      timeoutID = readOpportunityWithLocalStorageToken();
    }

    return () => {
      setIsAppLoading(false);
      timeoutID && clearTimeout(timeoutID);
    };
  }, []);

  const readOpportunityWithLocalStorageToken = (): any => {
    const token = localStorage.getItem('token');
    trackCustomAction(
      `readTestLocalSotrage2:"${localStorage.getItem('test')}"`
    );
    let timeoutID;
    if (!token) {
      timeoutID = setTimeout(() => {
        const retryToken = localStorage.getItem('token');
        if (!retryToken)
          trackCustomAction(
            `readOpportunityWithLocalStorageToken - No Token in LocalStorage: "${retryToken}"`
          );
        // ANYWAY do FETCH DATA To GET 401
        readOpportunities(appNavigation, searchParams, retryToken);
      }, 1000);
    } else {
      readOpportunities(appNavigation, searchParams, token);
    }
    return timeoutID;
  };

  /**
   * This method is used to get the return url method done by external applications (Monext, OnBo, 3DS)
   * So what happenned if user tape it manually on navigator ??
   */
  const redirectToWaitingPage = (
    urlParam: URLSearchParams,
    applicationNavigation: INavigationState
  ) => {
    setWaitingPage(false);
    let step;
    let parameterName;
    const activeStep = getActiveStep(applicationNavigation?.steps);
    if (
      urlParam.has('redirectFromMonext') &&
      activeStep?.externalAppName === 'monext'
    ) {
      // Redirect user directly to onbo if an url already exists on the store
      parameterName = 'redirectFromMonext';
      step = 'attente-cb';
      if (urlParam.get('returnCode')) {
        const monextResponse: MonextResponse = {
          cardId: urlParam.get('cardId'),
          cardRef: urlParam.get('cardRef'),
          returnCode: urlParam.get('returnCode'),
          returnValue: urlParam.get('returnValue'),
          sign: urlParam.get('sign'),
        };

        // Update store with parameters monext
        dispatch(setMonexResponse(monextResponse));
      }
    } else if (
      urlParam.has('redirectFromSignature') &&
      activeStep?.externalAppName === 'onbo'
    ) {
      const isVatRib = isRibRegExp.test(applicationNavigation?.vatType ?? '');
      localStorage.removeItem('urlOnboarding');
      parameterName = 'redirectFromSignature';
      step = !!isVatRib ? 'attente-onboarding-rib' : 'attente-onboarding';
      if (urlParam.get('signed') || urlParam.get('onboardingError')) {
        const onbResponse: IOnBoResponse = {
          returnCode: urlParam.get('onboardingError'),
          sign: urlParam.get('signed'),
          storedSign: applicationNavigation?.randomSign,
        };
        dispatch(setOnboardingResponse(onbResponse));
      }
    } else if (
      urlParam.has('redirectFrom3DS') &&
      activeStep?.externalAppName === '3ds'
    ) {
      parameterName = 'redirectFrom3DS';
      step = 'attente-3ds';
      if (urlParam.get('returnCode')) {
        const monext3DsResponse: MonextResponse = {
          returnCode: urlParam.get('returnCode'),
          returnValue: urlParam.get('returnValue'),
          sign: urlParam.get('sign'),
          threeDsRef: urlParam.get('threeDsRef'),
        };
        // Update store with parameters monext
        dispatch(setMonexResponse(monext3DsResponse));
      }
    } else if (
      urlParam.has('redirectFromOpenBanking') &&
      activeStep?.externalAppName === 'openBanking'
    ) {
      parameterName = 'redirectFromOpenBanking';
      step = 'attente-openbanking';
    }

    if (parameterName) {
      const waitingPageUrl = `/to/${step}?${parameterName}`;
      setWaitingPage(true);
      setWaitingStep(step ?? '');
      navigate(waitingPageUrl);
      setIsAppLoading(false);
    } else {
      continueTreatment(
        appNavigation,
        lastPage,
        urlParam,
        activeStep,
        navSteps,
        containedError
      );
    }
  };

  // Displays the correct page on the browser within the given steps
  useEffect(() => {
    (steps ?? []).forEach((st) => {
      if (st.paths.includes(location.pathname)) {
        const stepsToDisplay = computeSteps(steps);
        const allSteps = computeStepsWithSubSteps(steps);
        const currentStepForStepper = allSteps.find(
          (cs) => cs.name === st.paths[0]
        );

        setCurrentStepWithSubStep(currentStepForStepper.step);

        if (!st.isSubStep) {
          const [computedStep] = stepsToDisplay.filter((cs) => cs.id === st.id);
          if (!computedStep) {
            return;
          }
          setCurrentStep(computedStep.step);
        } else {
          const stepsWithPrevious = [...stepsToDisplay, st].sort((st1, st2) =>
            ascSort(st1.id, st2.id)
          );

          // Get the previous step that contains the substep and select its step
          const previousStepIndex =
            stepsWithPrevious.findIndex((stp) => stp.id === st.id) - 1;

          setCurrentStep(stepsWithPrevious[previousStepIndex]?.step);
        }
        document.title = t('pageHeader');
      }
    });
  }, [location, steps]);

  /**
   * Compute the step to display on the stepline with its new step number
   * @param {IStep[]} steps
   * @return {}
   */
  const computeStepsWithSubSteps = (steps: IStep[]) => {
    let stepsToAdd = [];
    let visitedSteps = [];
    if (
      appNavigation.openBankingChoice === 'STANDARD' ||
      !isOpenBankingResultOk(appNavigation)
    ) {
      stepsToAdd = appNavigation?.appNavigationState;

      // get the list of pages based on the name
      const currentStepsName = steps.map((s) => s.paths[0]);

      //remove the duplicate
      visitedSteps = stepsToAdd?.filter(
        (s) => !currentStepsName.includes(s.name)
      );
    }

    //comnbine the steps
    const allStepsCombined = [...(visitedSteps ?? []), ...(steps ?? [])];

    return (allStepsCombined ?? []).map((s, index: number) => ({
      name: s.name || s.paths[0],
      step: index + 1,
    }));
  };
  const triggerWayTypeVATC = (
    wayType: string,
    planB: boolean,
    isNewToken: boolean,
    paramsArgs: IParametersArgs
  ) => {
    if (wayType === WAY_TYPE_CB) {
      const alreadyCalled = localStorage.getItem(PLANBKEY);
      let planBValue;
      checkAvailability(planB, alreadyCalled ?? 'false')
        .then(
          (resp) => {
            planBValue = resp.data?.planB;
          },
          (err) => {
            console.error(`error while calling getPlanB : ${err}`);
            planBValue = false;
          }
        )
        .finally(() => {
          localStorage.setItem(PLANBKEY, 'true');
          if (isNewToken || planBValue) {
            readParameters({ ...paramsArgs, planB: planBValue });
          } else {
            setIsAppLoading(false);
          }
        });
    }
  };
  const isOpenBankingCurrentPage =
    (lastPage === '/' &&
      steps
        ?.find((stp) => stp.step === 1)
        ?.paths.includes(OPENBANKINGCHOICE)) ||
    [OPENBANKING, OPENBANKINGCHOICE, OBCONNEXIONERROR].includes(lastPage);

  const isErrorOccured =
    containedError ||
    (isWaitingPage && isStringEmpty(waitingStep)) ||
    (!searchParams.has('token') &&
      getActiveStep(appNavigation?.steps ?? [])?.isErrorHappened);

  const showHeader =
    !appNavigation?.endParcours &&
    !isErrorOccured &&
    !isPageWithNoHeader &&
    !regexp.test(lastPage) &&
    !isAppLoading &&
    !isOpenBankingCurrentPage &&
    !internalPageLoading;

  const showFooter =
    !internalPageLoading &&
    !isPageWithNoFooter &&
    !isAppLoading &&
    (!isOpenBankingCurrentPage ||
      [SUCCESSREPONSE, REFUSEDREPONSE, WAITINGRESPONSE, LIVENESS].includes(
        lastPage
      ));

  const shouldDisplayRedirectionComponent =
    [REFUSEDREPONSE, SUCCESSREPONSE, WAITINGRESPONSE].includes(lastPage) ||
    ERRORPAGE === lastPage ||
    isErrorOccured;

  const LoaderCmp = (
    <LoadingWrapper>
      <Loader
        isLoading
        primaryText={t('common.loader.shortText')}
        secondaryText={t('common.loader.longText')}
        isTextInline={false}
      />
    </LoadingWrapper>
  );

  useEffect(() => {
    if (showFooter) {
      setAppOptions((state) => ({ ...state, isMounted: true }));
    }
  }, [showFooter]);

  return {
    t,
    isOrderSummaryPage,
    appOptions,
    isErrorOccured,
    isPageWithNoHeader,
    lastPage,
    isAppLoading,
    numVendor,
    currentStep,
    LoaderCmp,
    isWaitingPage,
    waitingStep,
    containedError,
    showFooter,
    navSteps,
    showHeader,
    readOpportunities,
    updatePageIsLoading,
    readParameters,
    redirectToWaitingPage,
    setAppOptions,
    updateError,
    // for test purpose
    continueTreatment,
    computeStepsWithSubSteps,
    currentStepWithSubStep,
    shouldDisplayRedirectionComponent,
  };
};

export default useApp;
