import { useLoadingHook, useManagingStep } from '@sweb-front/hooks';
import {
  IState,
  setIncomes,
  setOutgoings,
  setPersonalInformation,
  useAppDispatch,
  useAppSelector,
  updateParcoursNavigation,
} from '@sweb-front/store';
import { IPerson } from '@sweb-front/types';
import { findPriOverdraftAmount, trackEvent } from '@sweb-front/utils';
import { postData, setIdHashed } from '@vat/containers';
import {
  computeInitialTouched,
  computeStringToNumber,
  ErrorContext,
} from '@vat/utils';

import { useFormik } from 'formik';
import {
  FocusEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { SOLVALONG } from '@vat/configuration';

const MIN_INCOME_OUTCOME = 0;
const MAX_INCOME_OUTCOME = 99999;

type IncomeExpensesLongOptions = {
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
};

const INCOME_MAP = {
  income: 'SAL',
} as const;

const OUTGOING_MAP = {
  rentOrCredit: 'LOY',
  ongoingDebt: 'CCO',
  childSupport: 'ALI',
  monthlyExpenses: 'AGR',
} as const;

const useIncomesExpensesLongForm = (
  opportunity: IState,
  fields: string[],
  options?: IncomeExpensesLongOptions
) => {
  const dispatch = useAppDispatch();
  const parameters = useAppSelector((state) => state.parameters.state);
  const { goBackToStep, goToNextStep } = useManagingStep();
  const { validateOnBlur, validateOnChange } = options || {};
  const { t } = useTranslation();
  const { person = {} as IPerson } = opportunity;
  const updateError = useContext(ErrorContext);
  const [showExpensesBubbleInfo, setShowExpensesBubbleInfo] = useState(false);
  const [showBubbleInfo, setShowBubbleInfo] = useState(false);
  const [isOngoingDebtFocused, setIsOngoingDebtFocused] = useState(false);
  const { isActionLoading, updateIsActionLoading } = useLoadingHook();

  const salary = person?.incomes?.find(
    (income) => income.incomeTypeCd === 'SAL'
  );

  const [showBaseMonthIncome, setShowBaseMonthIncome] = useState(
    salary?.incomeAmt && salary.incomeAmt !== undefined && salary.incomeAmt > 0
  );

  const incomesExpensesLongSchema = z.object({
    income: z.coerce
      .number({
        required_error: t(
          'incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.required'
        ),
        invalid_type_error: t(
          'incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.format'
        ),
      })
      .min(
        MIN_INCOME_OUTCOME,
        t('incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.format')
      )
      .max(
        MAX_INCOME_OUTCOME,
        t('incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.format')
      ),
    rentOrCredit: z
      .string({
        invalid_type_error: t(
          'incomeAndExpensesLong.rentOrCredit.errors.required'
        ),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (!fields.includes('SOLVREV') && !fields.includes('SOLVCHRGS')) {
          if (
            value &&
            (computeStringToNumber(value) || computeStringToNumber(value) === 0)
          ) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['rentOrCredit'],
            message: t('incomeAndExpensesLong.rentOrCredit.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }
      }),
    ongoingDebt: z
      .string({
        invalid_type_error: t(
          'incomeAndExpensesLong.ongoingDebt.errors.required'
        ),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (!fields.includes('SOLVREV') && !fields.includes('SOLVCHRGS')) {
          if (
            value &&
            (computeStringToNumber(value) || computeStringToNumber(value) === 0)
          ) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['ongoingDebt'],
            message: t('incomeAndExpensesLong.ongoingDebt.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }
      }),
    childSupport: z
      .string({
        invalid_type_error: t(
          'incomeAndExpensesLong.childSupport.errors.required'
        ),
      })
      .optional()
      .superRefine((value, ctx) => {
        if (!fields.includes('SOLVREV') && !fields.includes('SOLVCHRGS')) {
          if (
            value &&
            (computeStringToNumber(value) || computeStringToNumber(value) === 0)
          ) {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.invalid_type,
            path: ['childSupport'],
            message: t('incomeAndExpensesLong.childSupport.errors.incorrect'),
            expected: 'string',
            received: 'string',
          });
        }
      }),
    monthlyExpenses: z.coerce
      .string({
        invalid_type_error: t(
          'incomeAndExpensesLong.form.errors.monthlyExpenses.format'
        ),
      })
      .min(
        MIN_INCOME_OUTCOME,
        t('incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.format')
      )
      .max(
        MAX_INCOME_OUTCOME,
        t('incomeAndExpensesLong.form.errors.monthlyIncomeAndExpenses.format')
      )
      .optional()
      .superRefine((value, ctx) => {
        if (fields.includes('SOLVREV') && fields.includes('SOLVCHRGS')) {
          if (value || value === '0') {
            return;
          }
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t(
              'incomeAndExpensesLong.form.errors.monthlyExpenses.format'
            ),
            path: ['monthlyExpenses'],
          });
        }
      }),
    monthNumberBaseCalculation: z.coerce
      .number()
      .optional()
      .superRefine((value, ctx) => {
        if (value) {
          return;
        }
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['monthNumberBaseCalculation'],
          message: t(
            'incomeAndExpensesLong.form.errors.monthNumberBaseCalculation.format'
          ),
        });
      }),
  });

  type IncomesExpensesLongSchema = z.infer<typeof incomesExpensesLongSchema>;

  const validate = (values) => {
    const result = incomesExpensesLongSchema.safeParse(values);
    if (result.success === true) {
      return;
    }
    const errors = result.error.errors.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.path[0]]: curr.message,
      };
    }, {});
    return errors;
  };

  const onSubmit = (values: IncomesExpensesLongSchema) => {
    if (isActionLoading) {
      return;
    }
    localStorage.setItem(
      'solva-rib',
      JSON.stringify({
        income: values.income,
        monthNumberBaseCalculation: values.monthNumberBaseCalculation,
      })
    );
    const computeIncomeAmtValue = (value) =>
      (typeof value === 'string' && computeStringToNumber(value)) || 0;
    const computeIncomeNbValue = (value) =>
      value && typeof value === 'string' && computeStringToNumber(value) > 0
        ? Number(values.monthNumberBaseCalculation)
        : null;
    const incomes = Object.entries(values)
      .map(([key, value]) => {
        if (INCOME_MAP[key]) {
          return {
            incomeAmt: computeIncomeAmtValue(value),
            incomeTypeCd: INCOME_MAP[key],
            incomeNb: computeIncomeNbValue(value),
          };
        }
        return undefined;
      })
      .filter((row) => row !== undefined);
    const outgoings = Object.entries(values)
      .map(([key, value]) => {
        if (OUTGOING_MAP[key] && value) {
          return {
            outgoingAmt: computeIncomeAmtValue(value),
            outgoingTypeCd: OUTGOING_MAP[key],
          };
        }
        return undefined;
      })
      .filter((row) => row !== undefined);
    const personObject = {
      personId: person.personId,
      incomes,
      outgoings,
    };
    dispatch(setIncomes(incomes as never));
    dispatch(setOutgoings(outgoings as never));
    updateIsActionLoading(true);
    postData(
      'vendors/opportunities/v1/opportunity',
      {
        persons: [personObject],
      },
      () => {
        updateIsActionLoading(false);
        dispatch(
          updateParcoursNavigation({
            name: SOLVALONG,
            loaded: true,
            actionPageDone: true,
            disabled: false,
          })
        );
        goToNextStep();
      },
      () => {
        dispatch(
          updateParcoursNavigation({
            name: SOLVALONG,
            loaded: true,
            actionPageDone: true,
            disabled: false,
            params: {
              hasError: true,
            },
          })
        );
        updateIsActionLoading(false);
        updateError();
      }
    );
  };

  const onIncomeChange = (value: string | undefined) => {
    formik.setFieldValue('income', value);
    setShowBaseMonthIncome(
      formik.values.income !== undefined && Number(formik.values.income) > 0
    );
  };

  const onIncomeFocus = () => {
    setShowBaseMonthIncome(
      formik.values.income !== undefined && Number(formik.values.income) > 0
    );
    setShowBubbleInfo(true);
  };

  const onIncomeBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setTimeout(() => setShowBubbleInfo(false), 250);
    setShowBaseMonthIncome(
      formik.values.income !== undefined && Number(formik.values.income) > 0
    );
    formik.handleBlur(e);
  };

  const onExpensesFocus = () => setShowExpensesBubbleInfo(true);

  const onExpensesBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setShowExpensesBubbleInfo(false);
    formik.handleBlur(e);
  };

  const { outgoings: storedOutgoings } = person;
  // Keep it for when backend can handle it
  // const storedIncome = storedIcomes?.find(
  //   (income) => income.incomeTypeCd === 'SAL'
  // );
  const storedIncome = JSON.parse(localStorage.getItem('solva-rib') || '{}');
  const storedRentOrCredit = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.rentOrCredit
  )?.outgoingAmt;
  const storedOngoingDebt = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.ongoingDebt
  )?.outgoingAmt;
  const storedChildSupport = storedOutgoings?.find(
    (storedOutgoing) =>
      storedOutgoing.outgoingTypeCd === OUTGOING_MAP.childSupport
  )?.outgoingAmt;

  const storedMonthlyExpenses = storedOutgoings?.find(
    (outgoing) => outgoing.outgoingTypeCd === OUTGOING_MAP.monthlyExpenses
  )?.outgoingAmt;

  const initialValues = {
    income:
      typeof salary?.incomeAmt === 'number' ? salary?.incomeAmt : undefined,
    monthNumberBaseCalculation: salary?.incomeNb,
    monthlyExpenses:
      storedMonthlyExpenses !== undefined
        ? String(storedMonthlyExpenses)
        : undefined,
    rentOrCredit:
      storedRentOrCredit !== undefined ? String(storedRentOrCredit) : undefined,
    ongoingDebt:
      storedOngoingDebt !== undefined ? String(storedOngoingDebt) : undefined,
    childSupport:
      storedChildSupport !== undefined ? String(storedChildSupport) : undefined,
  };

  const initialTouched = computeInitialTouched(initialValues);

  const formik = useFormik<Partial<IncomesExpensesLongSchema>>({
    initialValues,
    initialTouched,
    validateOnMount: storedIncome !== undefined,
    validateOnBlur,
    validateOnChange,
    validate,
    onSubmit,
  });

  useEffect(() => {
    if (opportunity?.opportunityIdExt) {
      const isMono: boolean = opportunity?.bagType === 'MONO';
      const { financialDetails } = opportunity?.offers?.[0]?.loans?.[0] ?? {};
      trackEvent({
        event: 'module_interaction',
        site: 'Ecommerce',
        workflow: parameters.wayCd,
        pageName: 'E-Commerce : Formulaire Solva : Revenus',
        // eslint-disable-next-line no-underscore-dangle
        environment: (window as unknown as WindowWithEnv)._env_?.env,
        visitorStatus: 'non-logged',
        opportunityIdHashed: setIdHashed(opportunity),
        ContributorCode: opportunity.distributor.distributorNb,
        Amount: (isMono
          ? financialDetails?.overdraftAmt
          : findPriOverdraftAmount(opportunity.offers[0].loans)
        )?.toString(),
        Rate: isMono ? financialDetails?.tncRt?.toString() : undefined,
        Term: isMono ? financialDetails?.term?.toString() : undefined,
        MonthlyPayment: isMono
          ? financialDetails?.monthlyPaymentWithoutInsuranceAmt?.toString()
          : undefined,
        pathType: isMono ? 'mono panier' : 'multi panier',
      });
    }
  }, []);

  const onRentOrCreditFocus = () => {
    formik.setTouched(
      {
        ...formik.touched,
        rentOrCredit: true,
        monthNumberBaseCalculation: true,
      },
      validateOnBlur
    );
  };

  const onOngoingDebtFocus = () => {
    setIsOngoingDebtFocused(true);
    formik.setTouched({ ...formik.touched, ongoingDebt: true }, validateOnBlur);
  };

  const onChildSupportFocus = () => {
    formik.setTouched(
      { ...formik.touched, childSupport: true },
      validateOnBlur
    );
  };

  const isRentOrCreditError = useMemo(
    () => !!formik.errors.rentOrCredit,
    [formik.errors.rentOrCredit]
  );

  const isOngoingDebtError = useMemo(
    () => !!formik.errors.ongoingDebt,
    [formik.errors.ongoingDebt]
  );

  const isChildSupportError = useMemo(
    () => !!formik.errors.childSupport,
    [formik.errors.childSupport]
  );

  const onHideOutgoingDebt = () => setIsOngoingDebtFocused(false);

  const onChangeRentOrCredit = useCallback(
    (v?: string) => {
      formik.setFieldValue('rentOrCredit', v);
    },
    [formik]
  );

  const onChangeOngoingDebt = useCallback(
    (v?: string) => {
      formik.setFieldValue('ongoingDebt', v);
    },
    [formik]
  );

  const onChangeChildSupport = useCallback(
    (v?: string) => {
      formik.setFieldValue('childSupport', v);
    },
    [formik]
  );

  const hasMonthNumberBaseCalculation =
    Number(formik.values.income) > 0
      ? formik.values.monthNumberBaseCalculation !== undefined
      : true;

  const isDirty =
    fields.includes('SOLVREV') && fields.includes('SOLVCHRGS')
      ? formik.values.income !== undefined &&
        hasMonthNumberBaseCalculation &&
        formik.values.monthlyExpenses !== undefined
      : formik.values.income !== undefined &&
        formik.values.monthNumberBaseCalculation !== undefined &&
        formik.values.childSupport !== undefined &&
        formik.values.ongoingDebt !== undefined &&
        formik.values.rentOrCredit !== undefined;

  return {
    formik,
    showBubbleInfo,
    showBaseMonthIncome,
    isDirty,
    isRentOrCreditError,
    isOngoingDebtFocused,
    isOngoingDebtError,
    showExpensesBubbleInfo,
    isChildSupportError,
    goBackToStep,
    onIncomeFocus,
    onExpensesFocus,
    onExpensesBlur,
    onRentOrCreditFocus,
    onOngoingDebtFocus,
    onHideOutgoingDebt,
    onIncomeChange,
    onIncomeBlur,
    onChildSupportFocus,
    onChangeRentOrCredit,
    onChangeOngoingDebt,
    onChangeChildSupport,
    setShowBubbleInfo,
    isActionLoading,
  };
};

export default useIncomesExpensesLongForm;
