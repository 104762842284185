export interface DtrumpApi {
  enterAction(actionName: string): number;
  leaveAction(actionId: number): void;
  identifyUser(value: string): void;
  addPageLeavingListener(listener: () => void): void;
  sendBeacon(): void;
}

declare global {
  interface Window {
    dtrum: DtrumpApi;
  }
}

export const trackCustomAction = (
  actionName: string,
  forceSend: boolean = true
): void => {
  if (window.dtrum) {
    window.dtrum.leaveAction(window.dtrum.enterAction(actionName));
    if (forceSend) window.dtrum.sendBeacon();
  }
};

export const trackLeavingPage = (trackCustomAction: () => void): void => {
  if (window.dtrum) {
    window.dtrum.addPageLeavingListener(trackCustomAction);
  }
};

export const trackUser = (userId: string): void => {
  if (window.dtrum) {
    window.dtrum.identifyUser(userId);
  }
};
