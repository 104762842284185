export const FRANCE_COUNTRY_CODE = 'FR';
export const UNKNOWN_VALUE = 'INCONNU';
export const MAX_LENGHT_BIRTH_CITY = 90;
export const MAX_LENGHT_BILLING_CITY = 38;
export const MIN_LENGHT_FIELD = 2;

export const emailRegex = new RegExp(
  `^[_A-Za-z0-9]+([.\\-+]?[_A-Za-z0-9]+)*@[A-Za-z0-9]+([.\\-]?[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$`,
  'i'
);

export const nameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð .'-]+$/u;

export const textRegex = /[^a-zA-ZÀÂÄÉÈÊËÏÎÔÖÙÜÛÇàâäéèêëïîôöùüûç '-]+/;
