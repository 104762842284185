import { Section, Title } from '@sweb-front/components';
import { NextButtonWrapper } from '@sweb-front/styles';
import styled from 'styled-components';

export const SectionWrapper = styled((props) => <Section {...props} />)`
  position: relative;
  padding: 0;
  color: var(--neutrals-500);
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 2.4rem;
  img {
    self-align: center;
  }
  & footer {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const ImgWrapper = styled.div`
  padding: 0;
  margin: 0;
  margin-top: 1.2rem;
  position: relative;
  height: 16.8rem;
  text-align: center;
  align-items: center;
`;

export const SVGIconWrapper = styled.div`
  z-index: 10;
  display: inline-flex;
  margin: 0;
  padding: 0;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-top: 1.6rem;
  margin-bottom: 3.2rem;
  width: 20rem;
  & base-icon {
    width: 100%;
    height: 100%;
  }
`;

export const ContentWrapper = styled.div`
  margin: 1.6rem 0;
  margin-top: 0;
  text-align: left;
`;

export const FirstGetContractCopyBold = styled.span`
  font-family: 'Open Sans Bold';
`;

export const TitleCmp = styled((props) => <Title {...props} />)`
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin: 0;
  text-align: center;
  color: var(--neutrals-700);
`;

export const Paragraph = styled.p<{ id?: string; isMobile?: boolean }>`
  font-family: 'Open Sans';
  color: var(--neutrals-500);
  margin: 0;
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 2.4rem;
`;

export const LoanHasBeenAcceptedContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3.2rem 0;
`;

export const LoanHasBeenAcceptedParagraph = styled(Paragraph)`
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: 'Open Sans Bold';
  color: var(--neutrals-700);
`;

export const ReceptionAndDownloadContainer = styled.div`
  position: relative;
  font-size: 1.4rem;
`;

export const ReceptionParagraph = styled(Paragraph)`
  position: relative;
  #approvedReceiptDocumentsBold {
    font-family: 'Open Sans Bold';
  }
`;

export const PrintAndDownloadParagraph = styled(Paragraph)`
  position: relative;
  font-size: 1.4rem;
  line-height: 2rem;
  color: var(--blue-500);
`;

export const PrintAndDownloadParagraphBold = styled.a`
  font-family: 'Open Sans Bold';
  font-size: 1.4rem;
  line-height: 2rem;
`;

export const CustomizedValidateButtonWrapper = styled(NextButtonWrapper)`
  width: 100%;
  margin: 3.2rem 0;
  & base-button {
    font-size: 1.8rem;
    line-height: 2.6rem;
    background: ${({ theme }) => theme.vendor.colors.primary};
    font-family: 'Open Sans Bold';
    --color-button: var(--neutrals-0);
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
    height: 5.6rem;
    padding: 1.6rem 0;
  }
  & base-button:hover {
    color: var(--neutrals-0);
  }
`;

export const DocumentInPersonalAccountParagraph = styled.div`
  font-family: 'Open Sans Bold';
  margin-top: 3.2rem;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: var(--neutrals-500);
  text-align: left;
`;

export const DocumentInPersonalAccountParagraphExplanation = styled(
  DocumentInPersonalAccountParagraph
)`
  font-family: 'Open Sans';
  margin-top: 1.6rem;
  text-align: left;
  font-size: 1.6rem;
  line-height: 2.4rem;
`;

export const BackButtonWrapper = styled(CustomizedValidateButtonWrapper)`
  & base-button {
    --color-button: ${({ theme }) => theme.vendor.colors.primary};
    background: var(--neutrals-0);
    border: 1px solid ${({ theme }) => theme.vendor.colors.primary};
    padding: 1.6rem 1.6rem;
    font-size: 1.8rem;
    line-height: 2.6rem;
  }
`;

export const ContentDescriptionWrapper = styled.div``;

export const MentionInformationWrapper = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 3.2rem;
  color: var(--neutrals-500);
  span {
    margin-left: 0.3rem;
  }
`;
