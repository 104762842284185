import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IOnBoResponse
} from '@sweb-front/types';

const getInitialState = () => {
  return { state: {} } as {
    state: {
      onboardingResponse: IOnBoResponse;
    };
  };
};

// Initial State
export const initialState = getInitialState();

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    clear(onboardingResponseState) {
      onboardingResponseState.state = getInitialState().state;
    },
    setOnboardingResponse(onboardingResponseState, action: PayloadAction<IOnBoResponse>) {
      onboardingResponseState.state.onboardingResponse = action.payload;
    },
  },
});

export const {setOnboardingResponse, clear } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
