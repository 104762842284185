import { BubbleInfo, NumericList } from '@sweb-front/components';
import { Trans, useTranslation } from 'react-i18next';
import { selectNavigation, useAppSelector } from '@sweb-front/store';
import { WaitingIconPage, Button } from '@vat/components';
import {
  ContentDescriptionWrapper,
  CustomizedValidateButtonWrapper,
  DocumentInPersonalAccountParagraphExplanation,
  FirstGetContractCopyBold,
  MentionInformationWrapper,
  NumericListWrapper,
  Paragraph,
  ParagraphTitle,
  PrintAndDownloadParagraph,
  SectionWrapper,
  SeparatorWrapper,
  SVGIconWrapper,
  TitleCmp,
} from './styles';
import useWaitingResponse from './useWaitingResponse';
import { CHANELTYPE } from '@sweb-front/types';
import { WAY_TYPE_CB } from 'src/libs/services/constants';

const WaitingResponse = () => {
  const { t } = useTranslation();
  const opportunity = useAppSelector((state) => state.opportunity.state);
  const { distributionChannelCD } = opportunity;
  const parameters = useAppSelector((state) => state.parameters.state);
  const navigation = useAppSelector(selectNavigation);
  const { onContractClick, deliveryMode } = useWaitingResponse(
    opportunity,
    parameters,
    navigation
  );
  const { wayType } = parameters;

  return (
    <SectionWrapper>
      <SVGIconWrapper>
        <WaitingIconPage />
      </SVGIconWrapper>
      <TitleCmp>{t('responses.waitingResponse.congratulations')}</TitleCmp>
      <Paragraph>{t('responses.waitingResponse.documentReceived')}</Paragraph>
      <ParagraphTitle>{t('responses.waitingResponse.nextStep')}</ParagraphTitle>
      <NumericListWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="1"
            title={t('responses.waitingResponse.step1.title')}
            description={t('responses.waitingResponse.step1.description')}
          />
        </SeparatorWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="2"
            title={t('responses.waitingResponse.step2.title')}
            description={
              distributionChannelCD === CHANELTYPE.POS
                ? t('responses.waitingResponse.step2.posDescription')
                : t('responses.waitingResponse.step2.description')
            }
          />
        </SeparatorWrapper>
        <SeparatorWrapper>
          <NumericList
            marginTopTitle="0rem"
            marginTopDescription="0.8rem"
            backgroundColor="#F2F3F3"
            listIndex="3"
            title={t('responses.waitingResponse.step3.title')}
            description={t('responses.waitingResponse.step3.description')}
          />
        </SeparatorWrapper>
      </NumericListWrapper>
      {wayType === WAY_TYPE_CB && (
        <BubbleInfo
          id="bubble-info-waiting-response"
          title={t(`responses.accepted.VAT.informationBubble`)}
          marker="default"
          hasIcon={true}
          hasChevron={false}
          textColor="#007590"
        />
      )}
      <PrintAndDownloadParagraph marginTop="3.2rem">
        <Trans
          i18nKey="responses.waitingResponse.downloadDocuments1"
          components={{
            downloadDocuments: (
              <strong id="downloadDocuments">downloadDocuments</strong>
            ),
          }}
        />
      </PrintAndDownloadParagraph>
      <CustomizedValidateButtonWrapper>
        <Button
          id="contract-download-button-waiting-response"
          level="primary"
          type="button"
          onClick={onContractClick}
        >
          {t(`responses.buttons.VAT.contractArea`)}
        </Button>
      </CustomizedValidateButtonWrapper>
      <ContentDescriptionWrapper>
        <FirstGetContractCopyBold>
          {t(`responses.accepted.VAT.firstGetContractCopy`)}
        </FirstGetContractCopyBold>
        <DocumentInPersonalAccountParagraphExplanation>
          {t(`responses.accepted.${wayType}.contractCopy`)}
        </DocumentInPersonalAccountParagraphExplanation>
        <MentionInformationWrapper>
          <sup>*</sup>
          <span>{t('responses.waitingResponse.mentionInformation')}</span>
        </MentionInformationWrapper>
      </ContentDescriptionWrapper>
    </SectionWrapper>
  );
};

export default WaitingResponse;
