import lazyRetry from './utils/lazyRetry';

// List of different URLs to facilitate its use through application
export const SUCCESSREPONSE = '/acceptee';
export const REFUSEDREPONSE = '/refusee';
export const WAITINGRESPONSE = '/demande-en-attente';
export const MENTIONSLEGALES = '/mentions-legales';
export const MENTIONSLEGALES100 = '/mentions-legales-100';
export const MENTIONSLEGALES140 = '/mentions-legales-140';
export const COOKIES = '/politique-cookies';
export const SMSMENTIONLEGALE = '/sms-mention-legale';
export const ERRORPAGE = '/erreur';
export const ERRORPAGEVATCB = '/erreur/cb';
export const ERRORPAGEVATRIB = '/erreur/rib';
export const EXPIREDSESSIONVATCB = '/expired/cb';
export const EXPIREDSESSIONVATRIB = '/expired/rib';
export const INFORMATIONBANCAIRES = '/informations-bancaires';
export const PIECESJUSTIFICATIVES = '/pieces-justificatives';
export const IDENTIFICATION_CETELEM = '/indentification';
export const SOLVA = '/revenus-charges';
export const ORDER_SUMMARY = '/order-summary';
export const KYC_STORE = '/informations-store';
export const SOLVA_CO = '/solva-co';
export const INSURANCE = '/options';
export const OPENBANKINGCHOICE = '/choix-open-banking';
export const SITUATIONFAMILIALE = '/situation-familiale';
export const REVENUSCHARCHECO = '/revenus-charges-co';
export const OPENBANKING = '/open-banking';
export const LOGEMENT = '/logement';
export const LIVENESS = '/liveness';
export const INFORMATIONS = '/informations';
export const INFORMATIONMAGASIN = '/informations-magasin';
export const SITUATIONPROF = '/situations-professionnelles';
export const SOLVALONG = '/revenus-charges-long';
export const ESIGNATURE = '/e-signatures';
export const VERIFSOLVA = '/verification-revenu-charges';
export const SITPRO = '/sitpro';
export const SITCO = '/sitco';
export const SOLVA_PRO = '/solvapro';
export const REDIRECTION = '/redirection';

// Lists all available steps of our application (only pages that are returned by the parameters)
export enum PAGE {
  ORDERSUMMARY = 'RECAP',
  IDCTLM = 'IDCTLM',
  KYC = 'KYC',
  KYCONLINE = 'KYCONLINE',
  KYCMAG = 'KYCMAG',
  SOLVA = 'SOLVA',
  SOLVAL = 'SOLVAL',
  SOLVACO = 'SOLVACO',
  SOLVAPRO = 'SOLVAPRO',
  SITCO = 'SITCO',
  SITPRO = 'SITPRO',
  VERIFSOLVA = 'VERIFSOLVA',
  SITFAM = 'SITFAM',
  LOGE = 'LOGE',
  PROF = 'PROF',
  ASSU = 'ASSU',
  CB = 'CB',
  UPPJ = 'UPPJ',
  ESIGN = 'ESIGN',
  CHOIXOB = 'CHOIXOB', // choix open banking RIB
  CHOIXOBCB = 'CHOIXOBCB', // choix open banking CB
  OBI = 'OBI',
  LIV = 'LIV',
}

// Lists the components name for each page
export enum PAGECMP {
  ORDERSUMMARY = 'OrderSummary',
  IDCTLM = 'IdentificationCetelem',
  INFORMATION = 'Information',
  INFORMATION_WESTCOAST = 'InformationWest',
  INFORMATIONMAGASIN = 'InformationMagasin',
  INCOMESEXPENSES = 'IncomesExpenses',
  OPTIONS = 'Options',
  CARDINFORMATION = 'CardInformation',
  SUPPORTIVEINFORMATION = 'SupportiveInformation',
  ESIGNATURE = 'ESignature',
  ACCORDPRET = 'ApprovedPayment',
  SITUATIONFAMILIALE = 'SituationFamiliale',
  PROFESSIONALSITUATION = 'ProfessionalSituation',
  UPPJ = 'InitOnbo',
  HOUSING = 'Housing',
  SOLVAL = 'IncomesExpensesLong',
  CHOIXOPENBAKNING = 'OpenBankingChoice',
  SOLVACO = 'IncomesExpensesCO',
  SOLVAPRO = 'ProfessionalSituation',
  SITCO = 'IncomesExpensesCO',
  SITPRO = 'ProfessionalSituation',
  VERIFYSOLVA = 'VerifyIncomesExpenses',
  CHOIXOPENBAKNINGCB = 'OpenBankingChoiceCb',
  LIVENESS = 'Liveness',
  OBI = 'OBI',
}

// PAGES FICTIVES DE DEBRANCHEMENT
export const THREEDS = '/3ds';
export const DOAUTHORIZATION = '/do-authorization';

// PAGE APPELEE MAIS PAS DANS LES ETAPES
export const OBCONNEXIONERROR = '/ob-connexion-error';

export interface IPageDetails {
  url: string;
  key_label: string;
  components: React.ReactNode;
  isSubStep?: boolean;
  isHeaderIgnored?: boolean;
}

export interface IPageProperty {
  [s: string]: IPageDetails;
}

/**
 * Gérer la réponse du paramétrage pour se conformer à la configuration
 * cas spécifique pour SOLVA
 * @param name
 * @return {string} le nom du page
 */
export const computePageName = (name: string): string => {
  const regExp = new RegExp('^SOLVA[0-9]{1,}$');
  let page = name;
  if (regExp.test(name)) {
    page = name.replace(regExp, 'SOLVA');
  }
  return page;
};

// Builds for each page its URL, its title and its components from the data we gather behind
export const URL_MAPPING: IPageProperty = {
  [PAGE.ORDERSUMMARY]: {
    url: ORDER_SUMMARY,
    key_label: 'recap',
    isHeaderIgnored: true,
    components: PAGECMP.ORDERSUMMARY,
  },
  [PAGE.IDCTLM]: {
    url: IDENTIFICATION_CETELEM,
    key_label: 'loginCetelem',
    isHeaderIgnored: true,
    components: PAGECMP.IDCTLM,
  },
  [PAGE.KYC]: {
    url: INFORMATIONS,
    key_label: 'basicInformations',
    components: PAGECMP.INFORMATION,
  },
  [PAGE.KYCONLINE]: {
    url: INFORMATIONS,
    key_label: 'basicInformations',
    components: PAGECMP.INFORMATION_WESTCOAST,
  },
  [PAGE.KYCMAG]: {
    url: INFORMATIONMAGASIN,
    key_label: 'basicInformations',
    components: PAGECMP.INFORMATIONMAGASIN,
  },
  [PAGE.SOLVA]: {
    url: SOLVA,
    key_label: 'incomesAndExpenses',
    isSubStep: true,
    components: PAGECMP.INCOMESEXPENSES,
  },
  [PAGE.SOLVAPRO]: {
    url: SOLVA_PRO,
    key_label: 'solvapro',
    isSubStep: true,
    components: PAGECMP.SOLVAPRO,
  },
  [PAGE.SITCO]: {
    url: SITCO,
    key_label: 'sitco',
    isSubStep: true,
    components: PAGECMP.SITCO,
  },
  [PAGE.SITPRO]: {
    url: SITPRO,
    key_label: 'sitpro',
    isSubStep: true,
    components: PAGECMP.SITPRO,
  },
  [PAGE.ASSU]: {
    url: INSURANCE,
    key_label: 'insurance',
    components: PAGECMP.OPTIONS,
  },
  [PAGE.CB]: {
    url: INFORMATIONBANCAIRES,
    key_label: 'cardInformation',
    components: PAGECMP.CARDINFORMATION,
  },
  [PAGE.UPPJ]: {
    url: PIECESJUSTIFICATIVES,
    key_label: 'supportiveInformation',
    components: PAGECMP.UPPJ,
  },
  [PAGE.ESIGN]: {
    url: ESIGNATURE,
    key_label: 'esignature',
    components: PAGECMP.ESIGNATURE,
  },
  [PAGE.PROF]: {
    url: SITUATIONPROF,
    key_label: 'professionalSituation',
    isSubStep: true,
    components: PAGECMP.PROFESSIONALSITUATION,
  },
  [PAGE.SOLVAL]: {
    url: SOLVALONG,
    key_label: 'incomeAndExpensesLong',
    isSubStep: true,
    components: PAGECMP.SOLVAL,
  },
  [PAGE.SOLVACO]: {
    url: REVENUSCHARCHECO,
    key_label: 'incomeAndExpensesCO',
    isSubStep: true,
    components: PAGECMP.SOLVACO,
  },
  [PAGE.VERIFSOLVA]: {
    url: VERIFSOLVA,
    key_label: 'verifyIncomeAndExpenses',
    isSubStep: true,
    components: PAGECMP.VERIFYSOLVA,
  },
  [PAGE.SITFAM]: {
    url: SITUATIONFAMILIALE,
    key_label: 'situationFamiliale',
    isSubStep: true,
    components: PAGECMP.SITUATIONFAMILIALE,
  },
  [PAGE.LOGE]: {
    url: LOGEMENT,
    key_label: 'logement',
    isSubStep: true,
    components: PAGECMP.HOUSING,
  },
  [PAGE.CHOIXOB]: {
    url: OPENBANKINGCHOICE,
    key_label: 'openBanking',
    isSubStep: true,
    components: PAGECMP.CHOIXOPENBAKNING,
  },
  [PAGE.CHOIXOBCB]: {
    url: OPENBANKINGCHOICE,
    key_label: 'openBanking',
    isSubStep: true,
    components: PAGECMP.CHOIXOPENBAKNINGCB,
  },
  [PAGE.LIV]: {
    url: LIVENESS,
    key_label: 'liveness',
    isSubStep: true,
    components: PAGECMP.LIVENESS,
  },
  [PAGE.OBI]: {
    url: OPENBANKING,
    key_label: 'obPage',
    isSubStep: true,
    components: PAGECMP.OBI,
  },
};

const Information = lazyRetry(() => import('./layouts/Information'));
const InformationMagasin = lazyRetry(
  () => import('./layouts/InformationStore')
);
const IncomeExpenses = lazyRetry(() => import('./layouts/IncomesExpenses'));
const Options = lazyRetry(() => import('./layouts/Options'));
const CardInformation = lazyRetry(() => import('./layouts/CardInformation'));
const SupportiveInformation = lazyRetry(
  () => import('./layouts/SupportiveInformation')
);
const ESignature = lazyRetry(() => import('./layouts/ESignature'));
const ApprovedPayment = lazyRetry(() => import('./layouts/ApprovedPayment'));
const ProfessionalSituation = lazyRetry(
  () => import('./layouts/ProfessionalSituation')
);
const InitOnbo = lazyRetry(() => import('./layouts/InitOnbo'));

const SituationFamiliale = lazyRetry(
  () => import('./layouts/SituationFamiliale')
);
const HousingComponent = lazyRetry(() => import('./layouts/Housing'));
const IncomeExpensesLong = lazyRetry(
  () => import('./layouts/IncomesExpensesLong')
);
const IncomeExpensesCO = lazyRetry(() => import('./layouts/SituationCO'));
const OrderSummary = lazyRetry(() => import('./layouts/OrderSummary'));
const IdentificationCetelem = lazyRetry(() => import('./layouts/LoginCetelem'));
const VerifyIncomesExpenses = lazyRetry(
  () => import('./layouts/VerifyIncomesExpenses')
);
const OpenBankingChoiceComponent = lazyRetry(
  () => import('./layouts/OpenBankingChoice')
);
const LivenessComponent = lazyRetry(() => import('./layouts/Liveness'));
const OpenBanking = lazyRetry(() => import('./layouts/OpenBankingTink'));

// Return the matching components within its page
export const getMatchingComponents = (type?: PAGECMP) => {
  switch (type) {
    case PAGECMP.ORDERSUMMARY:
      return <OrderSummary />;
    case PAGECMP.IDCTLM:
      return <IdentificationCetelem />;
    case PAGECMP.PROFESSIONALSITUATION:
      return <ProfessionalSituation />;
    case PAGECMP.INFORMATION:
      return <Information />;
    case PAGECMP.INFORMATION_WESTCOAST:
      return <Information isWestCoast={true} />;
    case PAGECMP.INFORMATIONMAGASIN:
      return <InformationMagasin />;
    case PAGECMP.INCOMESEXPENSES:
      return <IncomeExpenses />;
    case PAGECMP.OPTIONS:
      return <Options />;
    case PAGECMP.CARDINFORMATION:
      return <CardInformation />;
    case PAGECMP.SUPPORTIVEINFORMATION:
      return <SupportiveInformation />;
    case PAGECMP.UPPJ:
      return <InitOnbo />;
    case PAGECMP.ESIGNATURE:
      return <ESignature />;
    case PAGECMP.HOUSING:
      return <HousingComponent />;
    case PAGECMP.SITUATIONFAMILIALE:
      return <SituationFamiliale />;
    case PAGECMP.SOLVAL:
      return <IncomeExpensesLong />;
    case PAGECMP.SOLVACO:
      return <IncomeExpensesCO />;
    case PAGECMP.VERIFYSOLVA:
      return <VerifyIncomesExpenses />;
    case PAGECMP.ACCORDPRET:
      return <ApprovedPayment />;
    case PAGECMP.CHOIXOPENBAKNING:
      return <OpenBankingChoiceComponent isVATRib={true} />;
    case PAGECMP.CHOIXOPENBAKNINGCB:
      return <OpenBankingChoiceComponent isVATRib={false} />;
    case PAGECMP.LIVENESS:
      return <LivenessComponent />;
    case PAGECMP.OBI:
      return <OpenBanking />;
    default: {
      return <Information />;
    }
  }
};

export enum INFORMATIONPAGE {
  KYCBLOC1 = 'KYCBLOC1',
  MKYCIDEN = 'M-KYCIDEN',
  KYCNOMNAI = 'KYCNOMNAI',
  KYCDTNAI = 'KYCDTNAI',
  KYCPAYSNAI = 'KYCPAYSNAI',
  KYCDPTNAI = 'KYCDPTNAI',
  KYCVILNAI = 'KYCVILNAI',
  KYCNATIO = 'KYCNATIO',
  KYCBLOC2 = 'KYCBLOC2',
  NMKYCMAIL = 'NM-KYCMAIL',
  MKYCMAIL = 'M-KYCMAIL',
  NMKYCTEL = 'NM-KYCTEL',
  MKYCTEL = 'M-KYCTEL',
  MKYCADR1 = 'M-KYCADR1',
  KYCBLOC3 = 'KYCBLOC3',
  KYCSOLVACT = 'SOLVACT',
  SECTEUR = 'SECTEUR',
}

export enum SOLVAPAGE {
  SOLVACT = 'SOLVACT',
  SOLVREV = 'SOLVREV',
  SOLVCHRGS = 'SOLVCHRGS',
  SOLVCHRGC = 'SOLVCHRGC',
  SOLVCPLT = 'SOLVCPLT',
}

export enum SOLVALPAGE {
  SOLVREV = 'SOLVREV',
  SOLVCHRGS = 'SOLVCHRGS',
}

export enum SOLVAPRO {
  SOLVACT = 'SOLVACT',
  DATPRO = 'DATPRO',
}

// Lists fields that can be present in each page
export const FORM_FIELDS_MAPPING = {
  KYC: {
    [INFORMATIONPAGE.KYCBLOC1]: 1, // civilité, nom, prénom
    [INFORMATIONPAGE.MKYCIDEN]: 1, // civilité, nom, prénom modifiable
    [INFORMATIONPAGE.KYCNOMNAI]: 2, // nom de naissance
    [INFORMATIONPAGE.KYCDTNAI]: 3, // date de naissance
    [INFORMATIONPAGE.KYCPAYSNAI]: 4, // pays de naissance
    [INFORMATIONPAGE.KYCDPTNAI]: 5, // département de naissance
    [INFORMATIONPAGE.KYCVILNAI]: 6, // ville de naissance
    [INFORMATIONPAGE.KYCNATIO]: 7, // nationalité
    [INFORMATIONPAGE.KYCSOLVACT]: 8, // nationalité
    [INFORMATIONPAGE.KYCBLOC2]: 9, // adresse de facturation
    [INFORMATIONPAGE.MKYCADR1]: 9, // adresse de facturation
    [INFORMATIONPAGE.MKYCMAIL]: 10, // adresse d'email modifable
    [INFORMATIONPAGE.MKYCTEL]: 11, // numéro de telephone
    [INFORMATIONPAGE.KYCBLOC3]: 12, // contact
    [INFORMATIONPAGE.NMKYCMAIL]: 12, // adresse d'email non modifiable (dans contact)
    [INFORMATIONPAGE.NMKYCTEL]: 12, // numéro de telephone modifiable
  },
  SOLVA: {
    [SOLVAPAGE.SOLVACT]: 1, // champ secteur d'activité
    [SOLVAPAGE.SOLVREV]: 2, // champ revenus et charges
    [SOLVAPAGE.SOLVCHRGS]: 3, // champ charges agrégées
    [SOLVAPAGE.SOLVCHRGC]: 4, // bloc 3 charges : [loyer, crédit, pensions]
    [SOLVAPAGE.SOLVCPLT]: 5, // bloc données complémentaires
  },
  SOLVAL: {
    [SOLVALPAGE.SOLVREV]: 1, // champ revenus mensuels
    [SOLVALPAGE.SOLVCHRGS]: 2, // champ charges mensuelles
  },
  SOLVAPRO: {
    [SOLVAPRO.SOLVACT]: 1, // champ secteur d'activité
    [SOLVAPRO.DATPRO]: 2, // champ ancienneté de l'emploi
  },
};

// Some variables that used to set in localStorage
export const STOREDVATTYPE = 'vatType';
export const STOREDSOLVAKEY = 'solvaData';
export const SOLVARIB = 'solva-rib';
export const STOREDPRFKEY = 'prfData';
export const STOREDASSUKEY = 'optionData';
export const ERRORKEY = 'errorCode';
export const ERRORBUSINESSCODE = 'errorBusinessCode';
export const PLANBKEY = 'planBWasCalled';
export const THEME = 'theme';
export const SCORE = 'score';

// Variable used to check if in url we have a certain fields that are useful
export const APPPARAMS = {
  redirectFromMonext: ['cardId', 'cardRef'],
  redirectFromSignature: [],
  redirectFrom3DS: ['threeDsRef'],
};

export enum SECTORCODES {
  privateEmployeeSector = 'SPRI',
  privateEmployeeNotCDI = 'SPRD',
  publicSector = 'SPU',
  craftsmenTradesmen = 'ACO',
  dgpdg = 'AUTD',
  autoEntrepreneur = 'ACOEN',
  freelances = 'PLI',
  agriculturalEmployee = 'SAGS',
  agriculturalOwner = 'SAGP',
  invalid = 'AUTI',
  publicPensioner = 'AUTRPC',
  privatePensioner = 'AUTRPR',
  others = 'AUT',
}

export const SECTORLIST = [
  'privateEmployeeSector',
  'privateEmployeeNotCDI',
  'publicSector',
  'craftsmenTradesmen',
  'dgpdg',
  'autoEntrepreneur',
  'freelances',
  'agriculturalEmployee',
  'agriculturalOwner',
  'invalid',
  'publicPensioner',
  'privatePensioner',
  'others',
];

export const MONTH_INCOME_BASE_CALCULATION = ['12', '13', '14', '15', '16'];

export const MONTHS_MAPPING = MONTH_INCOME_BASE_CALCULATION.map((value) => ({
  label: value,
  value,
}));

// Cause for now, we don't have a way to check if a vendor is orange, we have to declare it manually
export const VENDORLIST = {
  ORANGE: '1007355845',
};

export enum OPPORTUNITYSTATUS {
  ANUL = 'ANUL',
  APPR = 'APPR',
  REFU = 'REFU',
  PAPP = 'PAPP',
  NEW = 'NEW',
  ENCO = 'ENCO',
}

export enum KYCEDITABLEFIELDS {
  BirthCountry = 'birthCountryIsoCd',
  BirthDepartment = 'birthDepartment',
  BirthCityInseeCd = 'birthCityInseeCd',
  BirthCity = 'birthCity',
}

export const WEBAPAGE_TITLES = {
  [INFORMATIONS]: 'Formulaire KYC',
  [INFORMATIONMAGASIN]: 'Formulaire KYC magasin',
  [OPENBANKINGCHOICE]: 'Choix Open Banking',
  [SOLVA]: 'Formulaire Solva',
  [SITUATIONFAMILIALE]: 'Formulaire Solva : Situation familiale',
  [LOGEMENT]: 'Formulaire Solva : Logement',
  [SITUATIONPROF]: 'Formulaire Solva : Situation professionnelle',
  [SOLVALONG]: 'Formulaire Solva : Revenus',
  [INSURANCE]: 'Formulaire Assurance',
  [LIVENESS]: 'Liveness : Identité',
  [OBCONNEXIONERROR]: 'Open Banking : Connexion perdue',
  [SUCCESSREPONSE]: 'FV',
  [WAITINGRESPONSE]: 'FO',
  [REVENUSCHARCHECO]: 'Formulaire Solva : Situation familiale/logement',
  [SITPRO]: 'Formulaire Solva : Situation professionnelle',
  [SITCO]: 'Formulaire Solva : Situation familiale/logement',
  [SOLVA_PRO]: 'Formulaire Solva : Situation professionnelle',
  [KYC_STORE]: 'Formulaire KYC',
  [ORDER_SUMMARY]: 'Recapitulatif du panier',
  [REFUSEDREPONSE]: `E-Commerce : FR${
    localStorage.getItem(SCORE) ? ' final' : ''
  }`,
};
